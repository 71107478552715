<template>
	<div class="indexs">
		<div class="IN-top">
			<div class="w " style="font-size: 36px;color: #DA1010;cursor: pointer;" @click="back()">
				商家入驻
			</div>
		</div>
		<div class="w box1">
			<div class="texts"> 营业执照</div>
			<div class="ups">
				<!-- action="https://www.wumaikong.com/short_selling/front/sundry/upload" -->
				<el-upload
				  class="avatar-uploader"
				 
				  :action=thisurl
				  :show-file-list="false"
				  :on-success="handleAvatarSuccess"
				  :before-upload="beforeAvatarUpload">
				  <img v-if="imageUrl" :src="imageUrl" class="avatar">
				  <div v-else>
					  <img  class="el-icon-plus avatar-uploader-icon" src="../../assets/img/jiajia.png" alt="">
					  <div >上传营业执照图片</div>
				  </div>
				 
				</el-upload>
			</div>
			<div class="texts1">
				请上传扫描件，上传照片请横向，垂直拍摄
			</div>
		</div>
		<div class="w box2">
			<div class="texts" > 姓名（法人/经营者）</div>
			<input class="IN-t" type="text" v-model="addFromData.name" placeholder="输入姓名">
		</div>
		<div class="w box1" style="	margin-top: 10px;">
			<div class="texts" style="	margin-right: 25px;">(法人/经营者)身份证正反面</div>
			<div class="ups" style="margin-right: 15px;">
				<!-- action="https://www.wumaikong.com/short_selling/front/sundry/upload" -->
				<el-upload
				  class="avatar-uploader"
				 
				  :action=thisurl
				  :show-file-list="false"
				  :on-success="handleAvatarSuccess1"
				  :before-upload="beforeAvatarUpload">
				  <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
				  <div v-else>
					  <img  class="el-icon-plus avatar-uploader-icon" src="../../assets/img/jiajia.png" alt="">
					  <div >上传身份证正面图片</div>
				  </div>
				 
				</el-upload>
			</div>
			<div class="ups">
				<!-- action="https://www.wumaikong.com/short_selling/front/sundry/upload" -->
				<el-upload
				  class="avatar-uploader"
				 
				  :action=thisurl
				  :show-file-list="false"
				  :on-success="handleAvatarSuccess2"
				  :before-upload="beforeAvatarUpload">
				  <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
				  <div v-else>
					  <img  class="el-icon-plus avatar-uploader-icon" src="../../assets/img/jiajia.png" alt="">
					  <div >上传身份证反面图片</div>
				  </div>
				 
				</el-upload>
			</div>
			<div class="texts1" >
				请上传扫描件，上传照片请横向，垂直拍摄
			</div>
		</div>
		<div class="w box2">
			<div class="texts" style="margin-right: 186px;"> 联系电话</div>
			<input class="IN-t" type="text" v-model="addFromData.phone" placeholder="输入电话号码">
		</div>
		<div class="w box2">
			<div class="texts" style="margin-right: 205px;"> 验证码</div>
			<input class="IN-t" type="text" v-model="addFromData.code" placeholder="输入验证码">
			<div class="loginbox-b-box-y-r" v-if="showDuan" @click="sundrySms">
				获取验证码
			</div>
			<div class="loginbox-b-box-y-r" v-else  @click="tishi">
				{{second}}S后重试
			</div>
		</div>
		<div class="w box3">
			<div class="texts"> 企业/店铺地址</div>
			<div class="child-box">
				<div style ="display: flex;align-items: center;font-size: 18px;">
					<div  style ="font-size: 18px;margin-right: 30px;">省/市/区</div>
					<el-cascader class="addadd-b" :options="options" :multiple="true"
						:props="{ value: 'id' ,label:'name' ,children:'child' }" @change="handleChange">
					</el-cascader>
				</div>
				<div>
					<input class="IN-t" type="text" v-model="addFromData.address" placeholder="详细地址">
				</div>
				
			</div>
		</div>
		
		<div class="w xieyi" @click="dianji()">
			<div class="x-text" @click.stop="">
				已阅读并同意<div style="color:#0072FF ;cursor:pointer " @click="yonghuxieyi()">《入驻协议》</div>
			</div>
			<div class="xuabuttom">
				<div class="x-child" v-if="showsA">
					
				</div>
			</div>
			
		</div>
		
		
		<div class="w">
			<div class="buttom" @click="updata">提交</div>
		</div>
		
	</div>
</template>

<script>
	import aioxs from 'axios'
	import {
		companyAAddAttr,
		sundrySms,//获取验证码
	} from "../../utils/index.js"
	export default {
		data() {
			return {
				thisurl:'https://www.wumaikong.com/short_selling/front/sundry/upload',
				//thisurl:'api/front/sundry/upload',
				addFromData: {
					name: "",
					phone: "",
					province: "",
					city: "",
					area: "",
					code:"",
					address: "",
					imageA:'',
					imageB:'',
					imageC:'',
				},
				options: [],
				imageUrl: '',
				imageUrl1: '',
				imageUrl2: '',
				citylist:[],
				shows:false,
				showsA:false,
				showDuan:true,
				second:59,
			}
		},
		created() {
			this.disposeAddress()
		},
		methods:{
			// textup(){
			// 	aioxs({
			// 		method: 'post',
			// 		url: 'api/front/company/addAttr',
			// 		data:this.addFromData,
			// 		responseType: 'json',
			// 	}).then(res=>{
			// 		console.log(res)
			// 	})
			// },
			dianji(){
				this.showsA=!this.showsA
			},
			yonghuxieyi(){
				this.$router.push('/ruzhu');
			},
			tishi(){
				this.$message({
					message:'请勿重复点击',
					type: 'error'
				})
			},
			sundrySms(){
				if(this.addFromData.phone==''){
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
					return
				}else if(!(/^1[3456789]\d{9}$/).test(this.addFromData.phone)){
						this.$message({
							message: '请输入正确的手机号码',
							type: 'warning'
						});
						this.addFromData.phone=''
						return
				}else{
					sundrySms({
						phone:this.addFromData.phone
					}).then(res=>{
						//console.log("验证码",res)
						if(res.code==200){
							this.$message({
								message: '发送验证码成功',
								type: 'success'
							});
							this.upcode()
						}
					})
				}
			},
			upcode(){
				this.showDuan=false
				let interval = setInterval(() => {
					--this.second
					}, 1000)
				setTimeout(() => {
					clearInterval(interval)
					this.showDuan = true
					this.second = 59
					// this.disabled = false
					// this.changess = true;
				}, 60000)
			},
			updata(){
				this.options.forEach(e=>{
					if(e.id==this.citylist[0]){
						this.addFromData.province=e.name
						e.child.forEach(es=>{
							if(es.id==this.citylist[1]){
								this.addFromData.city=es.name
								es.child.forEach(ess=>{
									if(ess.id==this.citylist[2]){
									this.addFromData.area=ess.name
									}
								})
							}
						})
					}
				})
				
				
				if (this.showsA==false){
						this.$message({
							message: '请勾选协议',
							type: 'warning'
						});
						return
				}else if(this.addFromData.name == "" || this.addFromData.phone == "" || this.addFromData.area == "" || this
					.addFromData.imageA == "" || this.addFromData.address == ""||this
					.addFromData.imageB == "" ||this
					.addFromData.imageC == "" ) {
					this.$message({
						message: '请完善信息',
						type: 'warning'
					});
					return
				}else if(this.addFromData.code=="" ) {
					this.$message({
						message: '填写验证码',
						type: 'warning'
					});
					return
				}{
					if(!(/^1[3456789]\d{9}$/).test(this.addFromData.phone)){
						this.$message({
							message: '请输入正确的手机号码',
							type: 'warning'
						});
					}else{
						this.companyAAddAttr()
						//this.textup()
					}
				}
			},
			companyAAddAttr(){
				companyAAddAttr(this.addFromData).then(res=>{
					console.log("商家入驻",res)
					if(res.code==200){
						// this.$message({
						// 	message: '提交成功',
						// 	type:'success'
						// });
						this.$router.push({
							path:'/REscess'
						})
					}else{
						this.$message({
					 	message: res.message,
					 	type:'warning'
						 });
					}
				})
			},
			// 处理地址
			disposeAddress() {
				this.options = JSON.parse(localStorage.getItem("$citygetAll"))
			},
			handleChange(value) {
				this.citylist=value
				//console.log(this.citylist);
				
				// this.addFromData.province = value[0]
				// this.addFromData.city = value[1]
				// this.addFromData.area = value[2]
			},
			handleAvatarSuccess(res, file) {//营业执照
				this.imageUrl = res
				this.addFromData.imageC=res;
			},
			handleAvatarSuccess1(res, file) {//身份证正面
				console.log('img',res)
				this.imageUrl1 = res;
				this.addFromData.imageA=res
			},
			handleAvatarSuccess2(res, file) {//身份证反面
				this.imageUrl2 = res
				this.addFromData.imageB=res
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
		
				if (!isJPG) {
				  this.$message.error('上传图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
				  this.$message.error('上传图片大小不能超过 2MB!');
				}
			return isJPG && isLt2M;
			},
			back(){
				this.$router.push({
					path:'/'
				})
			}
		}
	}
</script>

<style lang="less">
	page{
		background-color: #F8F8F8;
	}
	.w{
		width: 1200px;
		margin: auto;
		position: relative;
	}
	
	.loginbox-b-box-y{
		width: 400px;
		height: 60px;
		margin-top: 20px;
		
		.loginbox-b-box-y-l{
			width: 230px;
			height: 58px;
			padding: 0 20px;
			line-height: 58px;
			background: #FFFFFF;
			border: 1px solid #cccccc;
			font-size: 18px;
		}
		
	}
	.xieyi{
		margin-top: 25px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		.xuabuttom{
			width: 25px;
			height: 25px;
			background: #EDEDED;
			border: 1px solid #A7A7A7;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor:pointer;
			.x-child{
				width: 15px;
				height: 15px;
				background: #D80000;
				border-radius: 50%;
			}
		}
		.x-text{
			display: flex;
			flex-direction: row;
			margin-left: 20px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #666666;
		}
	}
	
	.indexs{
		.texts1{
			margin-left: 30px;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;
			color: red;
		}
		.buttom{
			position: absolute;
			right :0px;
			cursor: pointer;
			top:105px;
			width: 200px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			background: #DA1010;
			border-radius: 10px;
			font-size: 24px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FEFEFE;
		}
		.IN-top{
			//width: 1920px;
			height: 166px;
			background-color: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.box1{
			margin-top: 41px;
			background-color: #FFFFFF;
			height: 160px;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			.texts{
				margin-left: 45px;
				margin-right: 188px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
			}
			
			.ups{
				width: 150px;
				height: 112px;
				background: #F8F8F8;
				border: 1px solid #DFDFDF;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				 .avatar {
				    width: 150px;
				    height: 108px;
				    display: block;
				  }
			}
		}
		.box2{
			margin-top: 10px;
			background-color: #FFFFFF;
			height: 60px;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			.texts{
				margin-left: 45px;
				margin-right: 78px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
			}
			.IN-t{
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #666666;
				width: 310px;
				height: 36px;
				padding-left: 20px;
				background: #F8F8F8;
				border: none;
			}
			.loginbox-b-box-y-r{
				cursor: pointer;
				width: 130px;
				height: 60px;
				background: #D80000;
				text-align: center;
				line-height: 60px;
				font-size: 18px;
				font-weight: bold;
				color: #FFFFFF;
			}
		}
		.box3{
			margin-top: 10px;
			background-color: #FFFFFF;
			height: 118px;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			.texts{
				margin-left: 45px;
				margin-right: 136px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
			}
			.child-box{
				.IN-t{
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #666666;
					margin-top: 10px;
					width: 751px;
					height: 36px;
					padding-left: 20px;
					background: #F8F8F8;
					border: none;
				}
			}
		}
	}
</style>